<!--
 * @Description: 搜索
 * @Author: luocheng
 * @Date: 2021-11-03 10:34:33
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-27 10:40:54
-->
<template>
	<div class="search-box">
		<el-input
			class="filter-input"
			v-model="nameFilter"
			prefix-icon="el-icon-search"
			placeholder="搜索"
			clearable
			@input="onEmit"
		></el-input>
	</div>
</template>

<script>
import { Input } from 'element-ui';

export default {
	name: 'SearchBox',
	props: {
		value: {
			type: String,
			required: true,
			default: ''
		}
	},
	components: {
		'el-input': Input
	},
  data() {
    return {
      nameFilter: ''
    }
  },
	created() {
		this.nameFilter = this.value
	},
	methods: {
		onEmit() {
			this.$emit('input', this.nameFilter);
		}
	}
};
</script>

<style lang="less" scoped>
.search-box {
  width: 100%;
	box-sizing: border-box;
	:deep(.filter-input) {
		height: 32px;
		display: block;
		// background: #f4f4f4;
		background: #fff;
			border-radius: 4px;
		margin: 0;
		.el-input__inner {
			// background: #f4f4f4;
			background: #fff;
			border: none;
			overflow: hidden;
		}
		.el-input__icon {
			color: #161616;
			line-height: 32px;
		}
	}
}
</style>