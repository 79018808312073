<!--
 * @Description: 企业
 * @Author: luocheng
 * @Date: 2021-11-05 16:18:17
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-04-06 11:05:58
-->
<template>
	<div class="enterprise">
		<SearchBox v-model="archiName"></SearchBox>
		<article class="enterprise-main">
			<!-- <section class="group item">
				<GroupItem
					:title="'集团'"
					:stylObj="styleObj"
				></GroupItem>
			</section> -->
			<section class="company item">
				<GroupItem
					:dataList="companyRenderData || []"
					:current="targetId"
					:title="'公司'"
					:stylObj="styleObj"
				></GroupItem>
			</section>
			<section class="company item">
				<!-- 信息 -->
				<ArchiInfo :reload="reload"></ArchiInfo>
			</section>
		</article>
	</div>
</template>

<script>
import SearchBox from '../SearchBox';
import GroupItem from '../GroupItem';
import ArchiInfo from '../ArchiInfo';
import { mapState } from 'vuex';

export default {
	name: 'Enterprise',
	components: {
		SearchBox,
		GroupItem,
		ArchiInfo
	},
	props: {
		reload: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			styleObj: {
				height: '412px'
			},
			// 公司数据
			companyData: [],
			targetId: null,
			// 架构名称筛选
			archiName: ''
		};
	},
	created() {
		this.companyData = JSON.parse(localStorage.getItem('myCompany')) || [];
		this.targetId = this.$GetTargetArchi("id");
	},
	computed: {
		companyRenderData() {
			return this.companyData.filter(ele => {
				const name = ele.name || ele.short_name || '';
				return name.includes(this.archiName || '');
			})
		},
		...mapState([
			'cacheArchi',
			'targetArchi'
		]),
		// 架构信息
		archiInfo() {
			return this.cacheArchi || this.targetArchi;
		}
	},
	watch: {
		companyRenderData: {
			handler() {
				// 将最右侧改为默认选中一个，逻辑为有当前项目则选中当前项目，没有则默认选中一个 @boss@晓雯 2023.4.6
				if (!this.companyRenderData.length) return;
				if (this.companyRenderData.find(ele => ele.id === this.archiInfo.id)) {
					return;
				}
				this.$store.commit('setCacheArchi', this.companyRenderData[0] || null);
			},
			deep: true,
			immediate: true
		}
	}
};
</script>

<style lang="less" scoped>
@itemWidth: 252px;
.enterprise {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.enterprise-main {
		flex: 1;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		padding-top: 16px;
		height: 412px;
		.item {
			width: @itemWidth;
			height: 100%;
			border-radius: 4px;
			overflow: hidden;
      &.company{
        // flex: 1;
      }
		}
	}
}
</style>