/*
 * @Description: 项目基础配置项
 * @Author: luocheng
 * @Date: 2022-03-21 16:01:06
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-13 17:40:19
 */
// 平台配置

export const projectObj = {
  ltsd: {
    logo: require('@/assets/images/szsw_logo.png'),
    fullName: '罗田水库-铁岗水库输水隧洞工程智慧建造平台',
    sortName: '罗田水库-铁岗水库输水隧洞工程智慧建造平台',
    little_logo: require('@/assets/images/szsw_logo.png')
  },
  nh: {
    // 南华项目
    logo: require('@/assets/images/nanhua_logo.png'),
    // fullName: '广州南华BIM+项目建管平台', // 暂时隐藏@陈忠
    fullName: 'BIM+项目建管平台',
    sortName: 'BIM+项目建管平台',
    little_logo: require('@/assets/images/nanhua_logo.png')
  },
  gzb: {
    // 葛洲坝项目
    // logo: require('@/assets/images/gzb_logo.png'),
    fullName: '葛洲坝建工数字化信息化操作平台',
    sortName: '葛洲坝建工数字化信息化操作平台',
    // little_logo: require('@/assets/images/gzb_logo_little.png')
  },
  pygxy: {
    // 濮阳工学院
    logo: require('@/assets/images/dev_logo.png'),
    fullName: '濮阳工学院新校区智慧建造平台',
    sortName: '濮阳工学院新校区智慧建造平台',
    little_logo: require('@/assets/images/dev_logo.png')
  },
  dev: {
    // 默认Dev项目
    logo: require('@/assets/images/dev_logo.png'),
    fullName: 'BIMCC数字建造平台',
    sortName: 'BIMCC数字建造平台',
    little_logo: require('@/assets/images/dev_logo.png')
  },
  cqjg: {
    // 默认Dev项目
    logo: require('@/assets/images/dev_logo.png'),
    fullName: 'BIMCC数字建造平台',
    sortName: 'BIMCC数字建造平台',
    little_logo: require('@/assets/images/dev_logo.png')
  }
}